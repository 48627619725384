import {
	AccentColorSchema,
	AgencyReport,
	AllThemesSchema,
	AgencyReportContentPostDataStats,
	AgencyReportReferral,
	AgencyReportContentComment,
	AgencyReportContent,
	AgencyReportCreator,
	AgencyReportPreview,
	AgencyReportCreatorFeedback,
} from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReportState = {
	report: AgencyReport;
	reports: AgencyReportPreview[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReportActions = {
	// Set Report
	setReport: (report: AgencyReport) => void;
	setPublished: (publish: boolean) => void;
	setOrder: (order: string[]) => void;

	// Set Cover
	setTitle: (title: string) => void;
	setSubtitle: (subtitle: string) => void;
	setBrandName: (name: string) => void;
	setBrandLogo: (uuid: string, url: string) => void;

	// Set Summary
	setStats: (stats: AgencyReportContentPostDataStats[]) => void;
	setShowSummary: (show: boolean) => void;

	// Set Referrals
	addReferral: (referral: AgencyReportReferral) => void;
	removeReferral: (uuid: string) => void;
	setReferrals: (referrals: AgencyReportReferral[]) => void;
	setShowReferrals: (show: boolean) => void;

	// Talent
	setCreators: (creators: AgencyReportCreator[]) => void;
	setContent: (content: AgencyReportContent[]) => void;
	setCommentsforContentAt: (
		uuid: string,
		comments: AgencyReportContentComment[],
	) => void;
	setStatsforContentAt: (
		uuid: string,
		stats: AgencyReportContentPostDataStats[],
	) => void;
	setShowCaptionforContentAt: (uuid: string, show: boolean) => void;

	// Feedback
	addFeedback: (feedback: AgencyReportCreatorFeedback) => void;
	updateFeedback: (feedback: AgencyReportCreatorFeedback) => void;

	// All Reports
	setReports: (reports: AgencyReportPreview[]) => void;
	addReport: (report: AgencyReportPreview) => void;
	setReportTitle: (title: string, uuid: string) => void;
	setReportPublished: (publish: boolean, uuid: string) => void;
};

export type ReportStore = ReportState & ReportActions;

export const defaultReportState = {
	report: {
		uuid: "",
		createdAt: new Date(),
		updatedAt: new Date(),
		title: "",
		subtitle: "",
		slug: "",
		order: [],
		brandName: "",
		brandLogo: {
			uuid: "",
			url: "",
			base64: "",
		},
		isPublished: false,
		isSummaryEnabled: true,
		areReferralsEnabled: true,
		content: [],
		agency: {
			uuid: "",
			name: "",
			username: "",
			theme: AllThemesSchema.Enum.july,
			accentColor: AccentColorSchema.Enum.dark,
			logo: {
				uuid: "",
				url: "",
				base64: "",
			},
		},
		creators: [],
		feedback: [],
		referrals: [],
		customTheme: null,
		metadata: {
			stats: [],
		},
	},
	reports: [],
};

export const createReportStore = (
	initState: ReportState = defaultReportState,
) => {
	return createStore<ReportStore>()(
		immer((set) => ({
			...initState,
			setReport: (report) => {
				set((state) => {
					state.report = report;
				});
			},
			setPublished: (publish) => {
				set((state) => {
					state.report.isPublished = publish;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.report.title = title;
				});
			},
			setSubtitle: (subtitle) => {
				set((state) => {
					state.report.subtitle = subtitle;
				});
			},
			setBrandName: (name) => {
				set((state) => {
					state.report.brandName = name;
				});
			},
			setStats: (stats) => {
				set((state) => {
					state.report.metadata.stats = [...stats];
				});
			},
			setShowSummary: (show) => {
				set((state) => {
					state.report.isSummaryEnabled = show;
				});
			},
			setShowReferrals: (show) => {
				set((state) => {
					state.report.areReferralsEnabled = show;
				});
			},
			addReferral: (referral) => {
				set((state) => {
					state.report.referrals.push(referral);
				});
			},
			removeReferral: (uuid) => {
				set((state) => {
					state.report.referrals = state.report.referrals.filter(
						(r) => r.uuid !== uuid,
					);
				});
			},
			setReferrals: (referrals) => {
				set((state) => {
					state.report.referrals = [...referrals];
				});
			},
			setOrder: (order) => {
				set((state) => {
					state.report.order = [...order];
				});
			},
			setCommentsforContentAt: (uuid, comments) => {
				set((state) => {
					const content = state.report.content.find((c) => c.uuid === uuid);
					if (content) {
						content.comments = [...comments];
					}
				});
			},
			setStatsforContentAt(uuid, stats) {
				set((state) => {
					const content = state.report.content.find((c) => c.uuid === uuid);
					if (content) {
						content.postData.stats = [...stats];
					}
				});
			},
			setContent: (content) => {
				set((state) => {
					state.report.content = [...content];
				});
			},
			setBrandLogo: (uuid, url) => {
				set((state) => {
					if (state.report.brandLogo) {
						state.report.brandLogo.uuid = uuid;
						state.report.brandLogo.url = url;
					} else {
						state.report.brandLogo = {
							uuid: uuid,
							url: url,
						};
					}
				});
			},
			setCreators: (creators) => {
				set((state) => {
					state.report.creators = [...creators];
				});
			},
			setShowCaptionforContentAt: (uuid, show) => {
				set((state) => {
					const content = state.report.content.find((c) => c.uuid === uuid);
					if (content) {
						content.isCaptionEnabled = show;
					}
				});
			},
			addFeedback: (feedback) => {
				set((state) => {
					state.report.feedback.push(feedback);
				});
			},
			updateFeedback: (feedback) => {
				set((state) => {
					state.report.feedback = state.report.feedback.map((fb) => {
						if (fb.creatorProfileUuid === feedback.creatorProfileUuid) {
							return feedback;
						} else {
							return fb;
						}
					});
				});
			},

			// functions for all reports
			setReports: (reports) => {
				set((state) => {
					state.reports = reports;
				});
			},
			addReport: (report) => {
				set((state) => {
					state.reports.unshift(report);
				});
			},
			setReportTitle: (title, uuid) => {
				set((state) => {
					const report = state.reports.find((r) => r.uuid === uuid);
					if (report) {
						report.title = title;
					}
				});
			},
			setReportPublished: (publish, uuid) => {
				set((state) => {
					const report = state.reports.find((r) => r.uuid === uuid);
					if (report) {
						report.isPublished = publish;
					}
				});
			},
		})),
	);
};
